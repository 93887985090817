import { render, staticRenderFns } from "./Recent.vue?vue&type=template&id=b5eaaaf6&scoped=true"
import script from "./Recent.vue?vue&type=script&lang=js"
export * from "./Recent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5eaaaf6",
  null
  
)

export default component.exports
import QTable from 'quasar/src/components/table/QTable.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTable,QBtnToggle,QIcon,QTooltip,QBtnDropdown,QToggle,QRadio,QBtn,QTh,QTd,QItem,QDialog,QCard,QSeparator,QCardActions,QCardSection});qInstall(component, 'directives', {ClosePopup});
