<template>
  <div>
    <q-table
      title="Logs"
      dense
      :data="Logs"
      :columns="columns"
      :loading="waitingForCustomWorkLogs || !Logs.length"
      color="blue-14"
      no-data-label="No logs"
      row-key="name"
      :rows-per-page-options="[0]"
      :pagination.sync="pagination"
      virtual-scroll
      class="sticky-header-table"
      style="height: calc(100vh - 55px)"
    >
      <template v-slot:top>
        <div class="q-pa-md full-width">
          <!-- Filtering -->
          <div class="q-gutter-md row">
            <q-btn-toggle
              size="sm"
              unelevated
              padding="xs"
              v-model="filters.billed"
              :toggle-color="getBilledColor(filters.billed)"
              :options="[
                { slot: 'all', value: -1 },
                { slot: 'unBilled', value: 0 },
                { slot: 'ready', value: 1 },
                { slot: 'billed', value: 2 }
              ]"
              :style="`border: 1px solid ${$q.dark.isActive ? '#fff' : '#000'}`"
            >
              <template v-slot:all>
                <q-icon name="list" size="sm">
                  <q-tooltip>Un filtered</q-tooltip>
                </q-icon>
              </template>
              <template v-slot:unBilled>
                <q-icon name="money_off" size="sm">
                  <q-tooltip>Un billed</q-tooltip>
                </q-icon>
              </template>
              <template v-slot:ready>
                <q-icon name="attach_money" size="sm">
                  <q-tooltip>Ready to bill</q-tooltip>
                </q-icon>
              </template>
              <template v-slot:billed>
                <q-icon name="receipt_long" size="sm">
                  <q-tooltip>Billed</q-tooltip>
                </q-icon>
              </template>
            </q-btn-toggle>
            <q-btn-dropdown color="primary" outline label="Categories">
              <div class="row no-wrap q-pa-md">
                <div class="column">
                  <div class="text-h6 q-mb-md">Filters</div>
                  <q-toggle
                    v-model="catSelectAll"
                    label="Select All"
                    class="q-mb-md"
                    @input="catSelectAllInput"
                  />
                  <div
                    v-for="(category, index) in filters.categories"
                    :key="index"
                    :style="buildBackColor(category.color, 0.2)"
                  >
                    <q-toggle
                      v-model="category.showItem"
                      :label="category.name"
                      @input="catFilterInput"
                    />
                  </div>
                </div>
              </div>
            </q-btn-dropdown>

            <q-btn-dropdown
              v-model="userFilterDropdown"
              color="primary"
              outline
              :label="filters.selectedUser === -1 ? 'Users:All' : 'User:Single'"
            >
              <div class="row no-wrap q-pa-md">
                <div class="column">
                  <div class="text-h6 q-mb-md">User filters</div>
                  <div v-for="(category, index) in filters.users" :key="index">
                    <q-radio
                      v-model="filters.selectedUser"
                      :val="category.id"
                      :label="category.name"
                      @input="userFilterInput"
                    />
                  </div>
                </div>
              </div>
            </q-btn-dropdown>

            <q-btn
              outline
              color="blue-14"
              label="Custom Date"
              @click="dialogDatePicker = true"
            />

            <q-btn
              outline
              @click="downloadXLSX"
              color="blue-14"
              icon="fas fa-file-excel"
            />
            <q-btn
              outline
              @click="downloadCSV"
              color="blue-14"
              icon="fas fa-file-csv"
            />
          </div>
          <!-- /Filtering -->
        </div>
      </template>

      <template v-slot:header-cell="props">
        <q-th :props="props" style="padding: 0; text-align: center">
          <div>
            {{ props.col.label }}
          </div>
          <div>
            {{ props.col.label2 }}
          </div>
        </q-th>
      </template>

      <!--Table Slots-->
      <template v-slot:body-cell-Date="props">
        <q-td :props="props" class="cursor-pointer">
          {{ parseTimeStamp(props.row.date, 'short') }}
          <q-tooltip v-if="isSuperAdmin">
            Created At:
            <br />
            {{ parseDateTime(props.row.created_date) }}
          </q-tooltip>
        </q-td>
      </template>

      <template v-slot:body-cell-Category="props">
        <q-td :props="props" :style="buildBackColor(props.row.color)">
          {{ serviceType(props.row.work_item) }}
        </q-td>
      </template>

      <template v-slot:body-cell-ToDo="props">
        <q-td :props="props">
          <div
            v-if="props.row.todo_flag !== 0"
            class="rounded-borders text-black text-caption q-pa-xs"
            :class="`bg-${buildToDoColor(props.row.todo_flag)}`"
            style="width: 16px; height: 10px"
          ></div>
        </q-td>
      </template>

      <template v-slot:body-cell-Farm="props">
        <q-td :props="props" class="ellipsis">
          {{ farmName(props.row.farm_id) }}
          <q-tooltip :delay="1000">
            {{ farmName(props.row.farm_id) }}
          </q-tooltip>
        </q-td>
      </template>

      <template v-slot:body-cell-Item="props">
        <q-td :props="props" class="ellipsis">
          {{ workItem(props.row.work_item) }}
          <q-tooltip :delay="1000">
            {{ workItem(props.row.work_item) }}
          </q-tooltip>
        </q-td>
      </template>

      <template v-slot:body-cell-Time="props">
        <q-td :props="props" class="ellipsis">
          <template v-if="valueType(props.row.work_item) !== 'fields'">
            {{
              parseFloat(props.row.time) !== 0 ? parseFloat(props.row.time) : ''
            }}
          </template>
          <template v-else>
            <q-btn
              class="btn-table-primary"
              icon="checklist_rtl"
              size="17px"
              @click="showWorkField(props.row)"
              padding="none 1px"
            >
            </q-btn>
          </template>
        </q-td>
      </template>

      <template v-slot:body-cell-Attachments="props">
        <q-td :props="props">
          <q-btn
            v-if="props.row.attachments.length"
            outline
            color="amber"
            size="sm"
            padding="6px 8px"
            @click="showAttachmentClick(props.row)"
          >
            <q-icon name="photo_library" color="amber" />
          </q-btn>
          <q-btn
            v-if="!props.row.attachments.length"
            outline
            color="grey-5"
            size="sm"
            padding="6px 8px"
            @click="showAttachmentClick(props.row)"
          >
            <q-icon name="photo_library" color="grey-5" />
          </q-btn>
        </q-td>
      </template>

      <template v-slot:body-cell-Chat="props">
        <q-td :props="props">
          <q-btn
            v-if="props.row.numUnread > 0"
            outline
            color="red-14"
            size="sm"
            padding="6px 8px"
            @click="showChatClick(props.row)"
          >
            <q-icon name="forum" color="red-14" />
          </q-btn>
          <q-btn
            v-if="props.row.numStarred > 0 && props.row.numUnread === 0"
            outline
            color="red-14"
            size="sm"
            padding="6px 8px"
            @click="showChatClick(props.row)"
          >
            <q-icon name="star" color="red-14" />
          </q-btn>
          <q-btn
            v-if="
              props.row.hasChat &&
              (isAuthForChat || isSuperAdmin) &&
              props.row.numImportant === 0
            "
            outline
            color="primary"
            size="sm"
            padding="6px 8px"
            @click="showChatClick(props.row)"
          >
            <q-icon name="forum" color="primary" />
          </q-btn>
          <q-btn
            v-if="!props.row.hasChat && (isAuthForChat || isSuperAdmin)"
            outline
            color="grey-5"
            size="sm"
            padding="6px 8px"
            @click="showChatClick(props.row)"
          >
            <q-icon name="forum" color="grey-5" />
          </q-btn>
        </q-td>
      </template>

      <template v-slot:body-cell-Reminders="props">
        <q-td :props="props">
          <q-btn
            v-if="props.row.reminderStatus.remindNow"
            round
            outline
            color="red-14"
            size="sm"
            icon="alarm"
            padding="7px"
            @click="remindersDisplay(props.row)"
          />
          <q-btn
            v-if="
              props.row.reminderStatus.hasReminder &&
              !props.row.reminderStatus.remindNow
            "
            round
            :outline="!props.row.reminderStatus.remindNow"
            color="deep-purple-14"
            size="sm"
            padding="7px"
            @click="remindersDisplay(props.row)"
          >
            <q-icon
              name="alarm"
              :color="
                props.row.reminderStatus.userReminder
                  ? 'red-14'
                  : 'deep-purple-14'
              "
            />
          </q-btn>
          <q-btn
            v-if="!props.row.reminderStatus.hasReminder"
            round
            outline
            color="grey-5"
            size="sm"
            icon="alarm"
            padding="7px"
            @click="remindersDisplay(props.row)"
          />
        </q-td>
      </template>

      <template v-slot:body-cell-OfficeNotes="props">
        <q-td :props="props">
          <div class="row items-center">
            <q-item
              clickable
              @click="showOfficeNoteClick(props.row)"
              class="ellipsis-2-lines"
              style="max-height: 48px; white-space: pre-line !important"
            >
              <q-icon
                v-if="props.row.office_comments.length > 0"
                right
                size="20px"
                color="primary"
                name="speaker_notes"
                class="q-mr-xs"
              />
              {{ props.row.office_notes }}
            </q-item>
          </div>
        </q-td>
      </template>

      <template v-slot:body-cell-Notes="props">
        <q-td :props="props">
          <q-item
            clickable
            @click="showNoteClick(props.row)"
            class="ellipsis-2-lines"
            style="max-height: 48px; white-space: pre-line !important"
          >
            <q-icon
              v-if="props.row.comments.length > 0"
              right
              size="20px"
              color="primary"
              name="chat_bubble_outline"
              class="q-mr-xs"
            />
            {{ decoder(props.row.notes) }}
          </q-item>
        </q-td>
      </template>

      <template v-slot:body-cell-Billed="props">
        <q-td :props="props" class="cursor-pointer">
          <q-btn-toggle
            size="sm"
            padding="xs"
            glossy
            disable
            v-if="props.row.billable"
            :value="props.row.billed"
            :toggle-color="getBilledColor(props.row.billed)"
            :options="[
              { slot: 'unBilled', value: 0 },
              { slot: 'ready', value: 1 },
              { slot: 'billed', value: 2 }
            ]"
          >
            <template v-slot:unBilled>
              <q-icon name="money_off" size="sm" />
            </template>
            <template v-slot:ready>
              <q-icon name="attach_money" size="sm" />
            </template>
            <template v-slot:billed>
              <q-icon name="receipt_long" size="sm" />
            </template>
          </q-btn-toggle>
          <q-icon
            v-if="props.row.billable"
            color="primary"
            name="info"
            size="xs"
            class="q-ml-sm cursor-pointer"
          >
            <q-tooltip content-class="bg-grey-3">
              <div class="text-black">
                Changed By:
                <br />
                {{ userName(props.row.billed_changed_by) }}
                <br />
                <br />
                Changed On:
                <br />
                {{ parseDateTime(props.row.billed_changed_on) }}
              </div>
            </q-tooltip>
          </q-icon>
        </q-td>
      </template>

      <template v-slot:body-cell-Assigned="props">
        <q-td :props="props" class="ellipsis">
          {{ userName(props.row.assigned_to, true) }}
        </q-td>
      </template>

      <template v-slot:body-cell-Link="props">
        <q-td :props="props">
          <q-btn
            class="btn-table-primary"
            outline
            icon="link"
            size="md"
            padding="xs"
            @click="navigateFarm(props.row)"
          >
          </q-btn>
        </q-td>
      </template>
    </q-table>

    <!-- chat display-->
    <q-dialog persistent position="top" v-model="showChat">
      <q-card style="width: 800px">
        <div align="right" class="q-ma-md">
          <q-btn
            unelevated
            icon="clear"
            color="blue-14"
            text-color="black"
            @click="closeChat"
          />
        </div>
        <q-separator color="primary" style="padding-top 1px" />

        <div class="scroll q-pa-md" style="max-height: calc(100vh - 200px)">
          <WorkLogChats :selectedLog="selectedLog" ref="WorkLogChats" />
        </div>
      </q-card>
    </q-dialog>

    <!-- attachment display-->
    <q-dialog
      v-model="showAttachment"
      persistent
      :maximized="true"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card v-if="selectedLog">
        <q-card-actions>
          <q-btn
            outline
            label="Back"
            color="primary"
            icon="arrow_back"
            padding="xs sm"
            v-close-popup
          />
        </q-card-actions>
        <q-separator />

        <q-card-section
          style="height: calc(100vh - 60px)"
          class="scroll q-pt-lg"
        >
          <Attachments :parentObj="selectedLog" :parentType="'workLog'" />
        </q-card-section>
      </q-card>
    </q-dialog>

    <!-- note display-->
    <q-dialog persistent position="top" v-model="showNote">
      <q-card v-if="selectedLog" style="width: 800px">
        <div class="row items-center q-ma-md">
          <div class="col text-center text-h5 text-primary">Notes</div>
          <q-btn unelevated icon="clear" color="primary" v-close-popup />
        </div>
        <q-separator color="primary" />

        <div class="scroll q-pa-md" style="max-height: calc(100vh - 200px)">
          <span>
            {{ selectedLog.notes }}
          </span>
        </div>
        <q-separator color="primary" />

        <div class="scroll q-pa-md" style="max-height: calc(100vh - 200px)">
          <Comments :parentObj="selectedLog" :commentType="'workLog'" />
        </div>
      </q-card>
    </q-dialog>

    <!-- officeNote display-->
    <q-dialog persistent position="top" v-model="showOfficeNote">
      <q-card v-if="selectedLog && showOfficeNote" style="width: 800px">
        <div class="row items-center q-ma-md">
          <div class="col text-center text-h5 text-primary">Office Notes</div>
          <q-btn unelevated icon="clear" color="primary" v-close-popup />
        </div>
        <q-separator color="primary" />

        <div class="scroll q-pa-md" style="max-height: calc(100vh - 200px)">
          <span>
            {{ selectedLog.officeNotes }}
          </span>
          <span
            v-if="selectedLog.emailMsg.length > 0"
            class="text-weight-light"
          >
            {{ selectedLog.emailMsg }}
          </span>
        </div>
        <q-separator color="primary" />

        <div class="scroll q-pa-md" style="max-height: calc(100vh - 200px)">
          <OfficeComments :parentObj="selectedLog" />
        </div>
      </q-card>
    </q-dialog>

    <!-- field display -->
    <q-dialog position="top" v-model="showFields">
      <q-card>
        <q-card-section>
          <div class="text-h6">{{ parseTimeStamp(shownFields.date) }}</div>
          <div :style="buildBackColor(shownFields.color, 0.3)" class="text">
            {{ shownFields.work_cat_name }} : {{ shownFields.work_item_name }}
          </div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div
            v-for="(field, index) in fieldList(shownFields.work_item)"
            :key="index"
            class="row"
          >
            <div class="col-8" style="min-width: 250px">
              {{ decoder(field.name) }}
            </div>
            <div class="col">
              <template v-if="field.type === 'checkbox'">
                <q-icon
                  class="check-style check-color"
                  v-if="fieldDecoder(field, shownFields.field_values)"
                  name="check"
                ></q-icon>
                <q-icon v-else color="negative" name="clear"></q-icon>
              </template>
              <template v-else>
                {{ fieldDecoder(field, shownFields.field_values) }}
              </template>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!--Calendar / Date Picker-->
    <q-dialog v-model="dialogDatePicker" persistent position="top">
      <DatePicker
        :allowAllDates="false"
        :dateRangeSelection="dateRange.dateRangeSelected"
        @datesSelected="datesSelected"
      />
    </q-dialog>

    <!-- reminder dialog-->
    <q-dialog persistent v-model="dialogReminders">
      <WorkLogReminders :workLog="editLogCopy" :farmId="editLogCopy.farm_id" />
    </q-dialog>
  </div>
</template>

<script>
import Attachments from '@/components/Attachments';
import Comments from '@/components/workLogs/Comments';
import OfficeComments from '@/components/workLogs/OfficeComments';
import WorkLogChats from '@/components/workLogs/WorkLogChats';
import WorkLogReminders from '@/components/workLogs/WorkLogReminders';
import DatePicker from '@/components/DatePicker';

import {
  cloneObj,
  decoder,
  chatInfo,
  trimName,
  getReminderStatus
} from '@/lib/helpers';
import { parseTimeStamp } from '@/lib/date-utils';
import { colors, date, exportFile } from 'quasar';
import store from '@/store';
import xl from 'excel4node';
import image from '@/assets/pdf/AerWorx_logo_pdf.png';
import { todoOptions } from '@/lib/static-data';

export default {
  name: 'RecentWork',
  components: {
    Attachments,
    Comments,
    OfficeComments,
    WorkLogChats,
    WorkLogReminders,
    DatePicker
  },
  data() {
    return {
      dateRange: {
        dateRangeSelected: 'default',
        from: '',
        to: ''
      },
      dialogDatePicker: false,
      dialogReminders: false,
      catSelectAll: true,
      filters: {
        billed: -1,
        categories: [],
        users: [],
        selectedUser: -1
      },
      showFields: false,
      showAttachment: false,
      showChat: false,
      selectedLog: null,
      showNote: false,
      showOfficeNote: false,
      shownFields: {},
      showEditModal: false,
      editLogCopy: {
        reminders: []
      },
      editWorkCategory: null,
      userFilterDropdown: false,
      pagination: {
        sortBy: 'desc',
        descending: false,
        page: 1,
        rowsPerPage: 50
      },
      workLogsCustom: [],
      workLogsLoaded: false,
      waitingForCustomWorkLogs: false,
      columns: [
        {
          sortable: true,
          name: 'Date',
          label: 'Date',
          align: 'center',
          field: (row) => parseTimeStamp(row.date, 'short'),
          sort: (a, b, rowA, rowB) => rowB.date - rowA.date
        },
        {
          sortable: true,
          name: 'ToDo',
          label: 'ToDo',
          align: 'center',
          field: (row) => this.todoType(row.todo_flag),
          sort: (a, b, rowA, rowB) => rowB.todo_flag - rowA.todo_flag
        },
        {
          sortable: true,
          name: 'Farm',
          label: 'Farm',
          align: 'left',
          style: 'max-width: 110px',
          field: (row) => decoder(this.farmName(row.farm_id))
        },
        {
          sortable: true,
          name: 'Category',
          label: 'Category',
          align: 'center',
          field: (row) => this.serviceType(row.work_item)
        },
        {
          sortable: true,
          name: 'Item',
          label: 'Item',
          align: 'center',
          style: 'max-width: 120px',
          field: (row) => this.workItem(row.work_item)
        },
        {
          sortable: true,
          name: 'Pond',
          label: 'Pond',
          align: 'center',
          field: (row) => this.pondName(row.farm_id, row.pond_id),
          sort: (a, b, rowA, rowB) =>
            a.localeCompare(b, undefined, {
              numeric: true,
              sensitivity: 'base'
            })
        },
        {
          sortable: true,
          name: 'Time',
          label: 'Time',
          align: 'center',
          style: 'max-width: 80px',
          field: (row) => this.valueDecoder(row)
        },
        {
          sortable: true,
          name: 'Attachments',
          label: 'Attach',
          align: 'center',
          style: 'max-width: 45px',
          field: (row) => row.attachments,
          sort: (a, b, rowA, rowB) => b.length - a.length
        },
        {
          sortable: true,
          name: 'Chat',
          label: 'Chat',
          align: 'center',
          style: 'max-width: 45px',
          field: (row) => row.numImportant,
          sort: (a, b, rowA, rowB) => {
            if (rowA.numImportant === 0 && rowB.numImportant === 0) {
              return rowA.hasChat === rowB.hasChat ? 0 : rowA.hasChat ? -1 : 1;
            }
            return rowB.numImportant - rowA.numImportant;
          }
        },
        {
          required: true,
          sortable: true,
          name: 'Reminders',
          label: 'Remind',
          align: 'center',
          field: (row) => row.reminders,
          style: 'max-width: 45px',
          sort: (a, b, rowA, rowB) => {
            // Trick to make math.min work correctly
            const rowATSArr = [999999999999999];
            let rowARem = false;
            let rowATS = 0;
            rowA.reminders.forEach((x) => {
              if (x.reminderRecipient === this.currUserId) {
                rowARem = true;
                rowATS = x.reminderDate;
              }
              rowATSArr.push(x.reminderDate);
            });

            const rowBTSArr = [999999999999999];
            let rowBRem = false;
            let rowBTS = 0;
            rowB.reminders.forEach((x) => {
              if (x.reminderRecipient === this.currUserId) {
                rowBRem = true;
                rowBTS = x.reminderDate;
              }
              rowBTSArr.push(x.reminderDate);
            });

            if (rowARem && rowBRem) {
              return rowATS - rowBTS;
            }

            if (rowARem && !rowBRem) {
              return -1;
            }

            if (!rowARem && rowBRem) {
              return 1;
            }

            return Math.min(...rowATSArr) - Math.min(...rowBTSArr);
          }
        },
        {
          sortable: true,
          name: 'OfficeNotes',
          label: 'Office Notes',
          align: 'center',
          class: 'ellipsis-2-lines',
          style: 'max-width: 600px; min-width: 200px',
          field: (row) => decoder(row.office_notes)
        },
        {
          sortable: true,
          name: 'Notes',
          label: 'Field Notes',
          align: 'center',
          class: 'ellipsis-2-lines',
          style: 'max-width: 600px; min-width: 200px',
          field: (row) => decoder(row.notes),
          sort: (a, b, rowA, rowB) => {
            if (rowA.comments.length === 0 && rowB.comments.length === 0) {
              return 0;
            }
            if (rowA.comments.length > 0 && rowB.comments.length === 0) {
              return -1;
            }
            if (rowA.comments.length === 0 && rowB.comments.length > 0) {
              return 1;
            }
            return rowB.lastCommentTime - rowA.lastCommentTime;
          }
        },
        {
          sortable: true,
          name: 'Billed',
          label: 'Billing',
          align: 'center',
          field: (row) => row,
          sort: (a, b) => {
            const level = function (item) {
              if (!item.billable) {
                return 3;
              }
              return item.billed;
            };
            return level(a) - level(b);
          }
        },
        {
          sortable: true,
          name: 'Assigned',
          label: 'Assigned To',
          align: 'center',
          style: 'max-width: 110px',
          field: (row) => this.userName(row.assigned_to)
        },
        {
          name: 'Link',
          label: '',
          align: 'center',
          classes: 'max-width'
        }
      ]
    };
  },
  async mounted() {
    this.setBilledCol();
    this.setCategoryFilters();
    this.setUserFilters();

    if (this.$store.state.workLogs.length) {
      this.getInitialLoad();
    }
  },
  methods: {
    getInitialLoad() {
      this.workLogsLoaded = true;

      const to = date.formatDate(new Date(), 'YYYY/MM/DD');
      const from = date.formatDate(
        date.subtractFromDate(new Date(to), { days: 182 }),
        'YYYY/MM/DD'
      );
      const dateRangeSelected = 'default';

      this.datesSelected({ from, to, dateRangeSelected });
    },
    async datesSelected(dateRange) {
      this.dateRange = dateRange;
      this.dialogDatePicker = false;
      this.customWorkLogs = [];

      this.waitingForCustomWorkLogs = true;
      const from = Math.floor(+new Date(dateRange.from) / 1000);

      const endOfTo = date.endOfDate(dateRange.to, 'day');
      const to = Math.floor(+new Date(endOfTo) / 1000);

      store
        .dispatch('customWorkLogs', {
          dates: { to, from }
        })
        .then((results) => {
          results.disableCheck = true;
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.datesSelected(dateRange);
            }
          });
        });
    },
    remindersDisplay(row) {
      this.editLogCopy = cloneObj(row);
      this.dialogReminders = true;
    },
    catSelectAllInput() {
      this.filters.categories.forEach(
        (cat) => (cat.showItem = this.catSelectAll)
      );
    },
    catFilterInput() {
      let allTrue = true;
      this.filters.categories.forEach((cat) => {
        allTrue = cat.showItem ? allTrue : false;
      });
      this.catSelectAll = allTrue;
    },
    closeChat() {
      const msg = this.$refs.WorkLogChats.newMessage;

      if (msg !== '') {
        this.$q
          .dialog({
            title: 'Unsaved Text',
            message:
              'Are you sure you want to exit chat? You have unsaved text that will be lost.',
            cancel: true,
            persistent: true
          })
          .onOk(() => {
            this.showChat = false;
          });
      } else {
        this.showChat = false;
      }
    },
    chatHeight() {
      return window.innerHeight - 180;
    },
    setBilledCol() {
      if (!this.isSuperAdmin) {
        this.columns = this.columns.filter((x) => x.name !== 'Billed');
      }
    },
    buildExcelColor(id, lighten) {
      const category = this.$store.state.workCategories.find(
        (element) => element.id === id
      );
      return category
        ? // ? category.def_color
          colors.lighten(category.def_color, lighten || 1.0)
        : '000000';
    },
    buildToDoColor(todoFlag) {
      return todoOptions.find((x) => x.value === todoFlag)?.color ?? '';
    },
    todoType(todoFlag) {
      return todoOptions.find((x) => x.value === todoFlag)?.label ?? '';
    },
    reportData() {
      const logs = this.Logs;
      const sumTracker = {};
      const MainData = [];
      const fieldPages = {};
      const Pages = [];
      for (let i = 0; i < logs.length; i++) {
        const log = logs[i];
        let value = '';
        let sum;
        let row = null;
        if (this.valueType(log.work_item) === 'fields') {
          row = [];
          const page = [
            parseTimeStamp(log.date, 'shortExcel'),
            this.farmName(log.farm_id),
            this.pondName(log.farm_id, log.pond_id)
          ];
          sum = '';
          const fieldList = this.fieldList(log.work_item);
          const fieldKey = `${log.work_item}:${log.work_cat}`;
          // Secondary pages
          if (!fieldPages[fieldKey]) {
            fieldPages[fieldKey] = {
              cat: log.work_cat,
              title: log.work_cat_name,
              subTitle: log.work_item_name,
              types: [],
              columns: [],
              totals: [],
              isNum: []
            };
            fieldPages[fieldKey].data = [['Date', 'Farm', 'Pond']];
            fieldPages[fieldKey].types = ['date', 'string', 'string'];
            for (let j = 0; j < fieldList.length; j++) {
              fieldPages[fieldKey].data[0].push(fieldList[j].name);
              fieldPages[fieldKey].types.push(fieldList[j].type);
              if (fieldList[j].report) {
                fieldPages[fieldKey].columns.push(fieldList[j].name);
              }
            }
          }
          let index = 0;
          for (let j = 0; j < fieldList.length; j++) {
            let value;
            if (fieldList[j].report) {
              value = this.fieldDecoder(fieldList[j], log.field_values);
              if (fieldList[j].type === 'checkbox') {
                value = value ? 'Y' : 'N';
                sum = '';
              } else if (!isNaN(Number(value))) {
                value = Number(value);
                if (sumTracker[fieldList[j].id]) {
                  sumTracker[fieldList[j].id] += value;
                } else {
                  sumTracker[fieldList[j].id] = value;
                }
                sum = sumTracker[fieldList[j].id];
              } else {
                sum = '';
              }
              row.push({
                name: fieldList[j].name,
                value,
                sum
              });
              fieldPages[fieldKey].isNum[index] =
                fieldList[j].type === 'number';
              fieldPages[fieldKey].totals[index++] = sum;
            } else {
              value = this.fieldDecoder(fieldList[j], log.field_values);
              if (fieldList[j].type === 'checkbox') {
                value = value ? 'Y' : 'N';
              }
            }
            page.push(value);
          }
          fieldPages[fieldKey].data.push(page);
        } else {
          value = this.valueDecoder(log);
          // if (this.valueType(log.work_item) === 'number') {
          //   if (!isNaN(Number(value))) {
          //     value = Number(value);
          //   }
          // }
        }

        const outBill = ['U', 'R', 'B'];
        MainData.push({
          date: parseTimeStamp(log.date, 'shortExcel'),
          cat: log.work_cat,
          cat_name: log.work_cat_name,
          item_name: log.work_item_name,
          pond: this.pondName(log.farm_id, log.pond_id),
          farm: this.farmName(log.farm_id),
          value,
          billable: log.billable ? outBill[log.billed] : '-',
          user: this.userName(log.assigned_to),
          notes: log.notes,
          office_notes: log.office_notes,
          chats: log.chats,
          comments: log.comments,
          fields: row
        });
      }

      Object.keys(fieldPages).forEach((key) => {
        Pages.push({
          title: fieldPages[key].title,
          subTitle: fieldPages[key].subTitle,
          tab: fieldPages[key].subTitle.replace(
            /(-|\/|\?|\*|\||:|,|\[|\])/gm,
            '_'
          ),
          data: fieldPages[key].data,
          totals: fieldPages[key].totals,
          isNum: fieldPages[key].isNum,
          columns: fieldPages[key].columns,
          cat: fieldPages[key].cat,
          types: fieldPages[key].types
        });
      });

      return {
        main: MainData,
        fields: Pages
      };
    },
    downloadXLSX() {
      const cols = {
        A: 1,
        B: 2,
        C: 3,
        D: 4,
        E: 5,
        F: 6,
        G: 7,
        H: 8,
        I: 9,
        J: 10,
        K: 11
      };
      const report = this.reportData();

      report.main.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      });

      const wb = new xl.Workbook({
        author: 'AerWorx ' + process.env.VUE_APP_VERSION
      });
      const mainColumns = [
        {
          name: 'Date',
          width: 10.25
        },
        {
          name: 'Category',
          width: 15.25
        },
        {
          name: 'Item',
          width: 25.25
        },
        {
          name: 'Farm',
          width: 12.25
        },
        {
          name: 'Pond',
          width: 12.25
        },
        {
          name: 'Value',
          width: 12.25
        },
        {
          name: 'Bill',
          width: 2.9
        },
        {
          name: 'User',
          width: 14.25
        },
        {
          name: 'Office Notes',
          width: 45,
          style: wb.createStyle({
            alignment: {
              wrapText: true
            }
          })
        },
        {
          name: 'Field Notes',
          width: 45,
          style: wb.createStyle({
            alignment: {
              wrapText: true
            }
          })
        },
        {
          name: 'Sum',
          width: 10.25
        }
      ];

      const options = {
        pageSetup: {
          orientation: 'landscape',
          fitToWidth: 1,
          fitToHeight: 0
        },
        margins: {
          header: 0.0,
          footer: 0.0,
          top: 0.25,
          bottom: 0.25,
          left: 0.25,
          right: 0.25
        }
      };

      const ws = wb.addWorksheet('Main billing', options);
      const pic = ws.addImage({
        image: Buffer.from(image.substr(22), 'base64'),
        type: 'picture',
        position: {
          type: 'absoluteAnchor',
          x: '0.15in',
          y: '0.3in'
        }
      });
      pic._pxWidth /= 5;
      pic._pxHeight /= 5;

      const columnStyle = wb.createStyle({
        font: {
          name: 'Calibri',
          size: 12,
          bold: true
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: 'CCCCCC'
        }
      });
      const altRowStyle = wb.createStyle({
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: 'CCCCCC'
        }
      });
      const verticalTop = wb.createStyle({
        alignment: {
          vertical: 'top'
        }
      });
      const centerStyle = wb.createStyle({
        alignment: {
          horizontal: 'center',
          vertical: 'top'
        }
      });
      const billColors = {
        U: '#C10015',
        R: '#4CAF50',
        B: '#607D8B',
        '-': '#EEEEEE'
      };

      ws.cell(5, 1).string('Aerworx ' + process.env.VUE_APP_VERSION);
      ws.cell(2, 5).string('Report Range');
      ws.cell(3, 5)
        .string(report.main[0].date)
        .style({
          alignment: {
            horizontal: 'right'
          }
        });
      ws.cell(4, 5)
        .string(report.main[report.main.length - 1].date)
        .style({
          alignment: {
            horizontal: 'right'
          }
        });

      const fillColor = {
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: billColors.U
        }
      };

      ws.cell(2, 7)
        .string('U')
        .style({ ...centerStyle, ...fillColor });
      ws.cell(2, 8).string('Unbilled');
      fillColor.fill.fgColor = billColors.R;
      ws.cell(3, 7)
        .string('R')
        .style({ ...centerStyle, ...fillColor });
      ws.cell(3, 8).string('Ready To Bill');
      fillColor.fill.fgColor = billColors.B;
      ws.cell(4, 7)
        .string('B')
        .style({ ...centerStyle, ...fillColor });
      ws.cell(4, 8).string('Billed Out');
      ws.row(7).freeze();

      for (let i = 0; i < mainColumns.length; i++) {
        ws.cell(7, i + 1)
          .string(mainColumns[i].name)
          .style(columnStyle);
        ws.column(i + 1).setWidth(mainColumns[i].width);
      }

      let c = 8;
      const userHours = [];

      for (let y = 0; y < report.main.length; y++) {
        const item = report.main[y];
        const typeColor = {
          fill: {
            type: 'pattern',
            patternType: 'solid',
            fgColor: this.buildExcelColor(item.cat, 65)
          },
          alignment: {
            vertical: 'top'
          }
        };
        if (y % 2 === 1) {
          ws.cell(c, 1, c, 10).style(altRowStyle);
        }
        ws.cell(c, 1).string(item.date).style(verticalTop);
        ws.cell(c, 2).string(item.cat_name).style(typeColor);
        ws.cell(c, 3).string(item.item_name).style(typeColor);
        ws.cell(c, 4).string(decoder(item.farm)).style(verticalTop);
        ws.cell(c, 5).string(item.pond).style(verticalTop);
        if (typeof item.value === 'number') {
          ws.cell(c, 6).number(item.value).style(verticalTop);
        } else {
          ws.cell(c, 6).string(item.value).style(verticalTop);
        }
        if (item.billable === '-') {
          ws.cell(c, 7)
            .string(item.billable)
            .style({
              alignment: {
                horizontal: 'center',
                vertical: 'top'
              }
            });
        } else {
          ws.cell(c, 7)
            .string(item.billable)
            .style({
              alignment: {
                horizontal: 'center',
                vertical: 'top'
              },
              fill: {
                type: 'pattern',
                patternType: 'solid',
                fgColor: billColors[item.billable]
              }
            });
        }

        ws.cell(c, 8).string(item.user).style(verticalTop);
        ws.cell(c, 9)
          .string(item.office_notes)
          .style({
            alignment: {
              wrapText: true,
              vertical: 'top',
              indent: 3
            }
          });
        ws.cell(c, 10)
          .string(item.notes)
          .style({
            alignment: {
              wrapText: true,
              vertical: 'top',
              indent: 3
            }
          });
        c++;
        // Collapsible fields
        if (item.fields) {
          for (let f = 0; f < item.fields.length; f++) {
            ws.cell(c, cols.C).string(item.fields[f].name).style(typeColor);
            if (typeof item.fields[f].value === 'number') {
              ws.cell(c, cols.E).number(item.fields[f].value);
            } else {
              ws.cell(c, cols.E).string(item.fields[f].value);
            }
            if (typeof item.fields[f].sum === 'number') {
              ws.cell(c, cols.J).number(item.fields[f].sum);
            }
            ws.row(c).group(1, true);
            c++;
          }
        }

        for (let i = 0; i < item.comments.length; i++) {
          const com = item.comments[i];
          const comDate = new Date(com.time).toLocaleString('en-US', {
            timeStyle: 'short',
            dateStyle: 'short'
          });
          if (i === 0) {
            ws.cell(c, 1)
              .string('Comments')
              .style({
                font: {
                  bold: true
                }
              });
          }
          ws.cell(c, 2).string(this.userName(com.user));
          ws.cell(c, 3)
            .string(comDate)
            .style({
              font: {
                color: '#8c8c8c',
                size: 10
              }
            });
          ws.cell(c, 4, c, 10, true)
            .string(com.comment)
            .style({
              alignment: {
                wrapText: true
              },
              font: {
                color: '#333333',
                size: 10
              }
            });
          ws.row(c).group(1, false);
          c++;
        }

        const chatGroups = [];
        for (let i = 0; i < item.chats.length; i++) {
          const chat = item.chats[i];

          const chatIds = chat.chatGroup
            .map((x) => x.userId)
            .sort((a, b) => a - b);
          const chatIdsStr = JSON.stringify(chatIds);

          const groupStr = chat.chatGroup
            .map((x) => {
              const user = this.userList.find((u) => u.value === x.userId);
              return user ? trimName(user.label) : '~~~';
            })
            .join(' - ');

          const chatIndex = chatGroups.findIndex(
            (x) => x.chatIdsStr === chatIdsStr
          );

          if (chatIndex < 0) {
            chatGroups.push({
              chatIdsStr,
              groupStr,
              messages: [
                {
                  sentBy: chat.sentBy,
                  msg: chat.comment,
                  time: chat.time
                }
              ]
            });
          } else {
            chatGroups[chatIndex].messages.push({
              sentBy: chat.sentBy,
              msg: chat.comment,
              time: chat.time
            });
          }
        }

        for (let i = 0; i < chatGroups.length; i++) {
          const chat = chatGroups[i];
          if (i === 0) {
            ws.cell(c, 1)
              .string('Chats')
              .style({
                font: {
                  bold: true
                }
              });
          }
          ws.cell(c, 2, c, 10, true)
            .string(chat.groupStr)
            .style({
              font: {
                bold: true
              }
            });
          ws.row(c).group(1, false);
          c++;
          for (let j = 0; j < chat.messages.length; j++) {
            const msg = chat.messages[j];
            const msgDate = new Date(msg.time).toLocaleString('en-US', {
              timeStyle: 'short',
              dateStyle: 'short'
            });

            ws.cell(c, 2).string(this.userName(msg.sentBy));
            ws.cell(c, 3)
              .string(msgDate)
              .style({
                font: {
                  color: '#8c8c8c',
                  size: 10
                }
              });
            ws.cell(c, 4, c, 10, true)
              .string(msg.msg)
              .style({
                alignment: {
                  wrapText: true
                },
                font: {
                  color: '#333333',
                  size: 10
                }
              });
            ws.row(c).group(1, false);
            c++;
          }
        }

        // Need to add up hours here - this may be a string with words!!
        if (+item.value) {
          const userIndex = userHours.findIndex((user) => {
            return user.name === item.user;
          });

          if (userIndex >= 0) {
            userHours[userIndex].hours += +item.value;
          } else {
            userHours.push({
              name: item.user,
              hours: +item.value
            });
          }
        }
      }

      // write totals on bottom
      c += 1;
      ws.row(c).setHeight(32);
      ws.cell(c, cols.B)
        .string('Totals')
        .style({
          font: {
            name: 'Calibri',
            size: 28,
            bold: true
          }
        });
      c += 2;

      for (let i = 0; i < userHours.length; i++) {
        ws.cell(c, cols.A)
          .string('Hours')
          .style({
            font: {
              name: 'Calibri',
              size: 12,
              bold: true
            }
          });
        ws.cell(c, cols.B).string(userHours[i].name);
        ws.cell(c, cols.F).number(userHours[i].hours);

        ws.cell(c, cols.A, c, cols.F).style(altRowStyle);
        c += 2;
      }

      // ID and color
      for (let y = 0; y < report.fields.length; y++) {
        const item = report.fields[y];
        const typeColor = {
          fill: {
            type: 'pattern',
            patternType: 'solid',
            fgColor: this.buildExcelColor(item.cat, 65)
          }
        };
        // ws.cell(c, cols.H).string(JSON.stringify(item));
        ws.cell(c, cols.B).string(item.title).style(typeColor);
        ws.cell(c, cols.C).string(item.subTitle).style(typeColor);
        ws.cell(c, cols.E)
          .number(item.data.length - 1)
          .style({
            fill: {
              type: 'pattern',
              patternType: 'solid',
              fgColor: 'B4DE86'
            },
            alignment: {
              horizontal: 'center'
            },
            font: {
              bold: true
            }
          });
        let pondList = '';
        for (let i = 1; i < item.data.length; i++) {
          pondList += item.data[i][1];
          if (i + 1 < item.data.length) {
            pondList += ', ';
          }
        }
        ws.cell(c, cols.H)
          .string(pondList)
          .style({
            fill: {
              type: 'pattern',
              patternType: 'solid',
              fgColor: 'B4DE86'
            },
            alignment: {
              wrapText: true
            }
          });
        c++;
        for (let i = 0; i < item.columns.length; i++) {
          const total = item.totals[i];
          if (item.isNum[i] && typeof total === 'number') {
            ws.cell(c, cols.C).string(item.columns[i]);
            ws.cell(c, cols.E)
              .number(item.totals[i])
              .style({
                fill: {
                  type: 'pattern',
                  patternType: 'solid',
                  fgColor: '92D050'
                },
                alignment: {
                  horizontal: 'center'
                }
              });
            c++;
          }
        }
        c += 2;
      }

      // Print generation report
      ws.cell(c, cols.B).string('Report info');
      ws.cell(c, cols.C).string(
        parseTimeStamp(Date.now() / 1000, 'shortExcel')
      );
      ws.cell(c, cols.D).string('Aerworx ' + process.env.VUE_APP_VERSION);

      // build report pages
      for (let y = 0; y < report.fields.length; y++) {
        const fieldSet = report.fields[y];
        const wsR = wb.addWorksheet(fieldSet.tab, options);

        const pic1 = wsR.addImage({
          image: Buffer.from(image.substr(22), 'base64'),
          type: 'picture',
          position: {
            type: 'absoluteAnchor',
            x: '7.5in',
            y: '0.3in'
          }
        });
        pic1._pxWidth /= 5;
        pic1._pxHeight /= 5;

        wsR.cell(2, 1, 3, 3).style({
          fill: {
            type: 'pattern',
            patternType: 'solid',
            fgColor: this.buildExcelColor(fieldSet.cat, 65)
          },
          font: {
            name: 'Calibri',
            size: 22
          }
        });
        wsR.cell(2, 1).string(fieldSet.title);
        wsR.cell(3, 1).string(fieldSet.subTitle);
        wsR.row(2).setHeight(24);
        wsR.row(3).setHeight(24);

        let xLen = 0;

        if (fieldSet.title === 'Installation') {
          fieldSet.data.sort((a, b) =>
            a[2].localeCompare(b[2], undefined, {
              numeric: true,
              sensitivity: 'base'
            })
          );
        }

        for (let i = 0; i < fieldSet.data.length; i++) {
          const set = fieldSet.data[i];
          xLen = set.length;
          for (let s = 0; s < set.length; s++) {
            if (typeof set[s] === 'number') {
              wsR
                .cell(6 + i, 1 + s)
                .number(set[s])
                .style({
                  alignment: {
                    horizontal: 'center',
                    wrapText: true
                  }
                });
            } else {
              wsR
                .cell(6 + i, 1 + s)
                .string(set[s])
                .style({
                  alignment: {
                    horizontal: 'center',
                    wrapText: true
                  }
                });
            }
          }
        }
        for (let i = 0; i < fieldSet.types.length; i++) {
          if (fieldSet.types[i] === 'number') {
            // Insert SUM()
            const start = xl.getExcelCellRef(7, 1 + i);
            const end = xl.getExcelCellRef(6 + fieldSet.data.length, 1 + i);
            wsR
              .cell(6 + fieldSet.data.length + 1, i + 1)
              .formula(`SUM(${start}:${end})`)
              .style({
                fill: {
                  type: 'pattern',
                  patternType: 'solid',
                  fgColor: '92D050'
                },
                alignment: {
                  horizontal: 'center'
                }
              });
          }
        }
        wsR
          .cell(6 + fieldSet.data.length + 1, 2)
          .number(fieldSet.data.length - 1)
          .style({
            fill: {
              type: 'pattern',
              patternType: 'solid',
              fgColor: '92D050'
            },
            alignment: {
              horizontal: 'center'
            }
          });
        wsR.cell(6, 1, 6 + fieldSet.data.length, 1 + xLen).style({
          font: {
            name: 'Calibri',
            size: 13
          },
          width: 17
        });
        wsR.column(1).setWidth(16);
        wsR.column(2).setWidth(22);
      }

      wb.writeToBuffer().then((buffer) => {
        exportFile(`Work-${Math.floor(Date.now() / 1000)}.xlsx`, buffer);
      });
    },
    downloadCSV() {
      const logs = this.Logs;
      const sumTracker = {};
      let csv = 'Date,Category,Item,Farm,Pond,Value,Notes,Billed,User,Sum\r\n';
      for (let i = 0; i < logs.length; i++) {
        const log = logs[i];
        let value = '';
        let row, sum;
        if (this.valueType(log.work_item) === 'fields') {
          row = '';
          sum = '';
          const fieldList = this.fieldList(log.work_item);
          for (let j = 0; j < fieldList.length; j++) {
            if (fieldList[j].report) {
              let value = this.fieldDecoder(fieldList[j], log.field_values);
              if (fieldList[j].type === 'checkbox') {
                value = value ? 'Y' : 'N';
                sum = '';
              } else if (!isNaN(Number(value))) {
                if (sumTracker[fieldList[j].id]) {
                  sumTracker[fieldList[j].id] += Number(value);
                } else {
                  sumTracker[fieldList[j].id] = Number(value);
                }
                sum = sumTracker[fieldList[j].id];
              }
              row += `,,${fieldList[j].name},,${value},,,,,${sum},\r\n`;
            }
          }
        } else {
          value = this.valueDecoder(log);
        }
        csv += `${parseTimeStamp(log.date, 'short')},${log.work_cat_name},${
          log.work_item_name
        },`;
        csv += `${this.farmName(log.farm_id)},${this.pondName(
          log.farm_id,
          log.pond_id
        )},${value},"${log.notes}",`;
        const billable = log.billable ? (log.billed ? 'B' : 'U') : '-';
        csv += `${billable},${this.userName(log.assigned_to)},\r\n`;
        if (row) {
          csv += row;
        }
      }
      // const farm = this.$store.state.farmList.find(farm => farm.id === parseInt(this.farmId));
      exportFile(`Work-${Math.floor(Date.now() / 1000)}.csv`, csv);
    },
    getBilledColor(billed) {
      switch (billed) {
        default:
        case 0:
          return 'negative';
        case 1:
          return 'green';
        case 2:
          return 'blue-grey';
        case -1:
          return 'cyan-8';
      }
    },
    parseTimeStamp(value) {
      return parseTimeStamp(value, 'short');
    },
    showWorkField(fields) {
      this.shownFields = fields;
      this.showFields = true;
    },
    dateHelper(quasarDate) {
      const d = new Date(quasarDate);
      const time = d.getTime() / 1000 + (d.getTimezoneOffset() * 60 + 3600);
      return parseTimeStamp(time, 'short');
    },
    fieldDecoder(fieldDef, fields) {
      const item = fields[fieldDef.id];
      if (item) {
        switch (fieldDef.type) {
          case 'checkbox':
            return item || false;
          case 'date':
            return this.dateHelper(item);
          case 'number':
          case 'text':
          case 'fields':
            return item;
          default:
            return '!!';
        }
      } else {
        switch (fieldDef.type) {
          case 'checkbox':
            return false;
          default:
            return '--';
        }
      }
    },
    valueMask(itemId) {
      const workItem = this.$store.state.workItems.find(
        (element) => element.id === itemId
      );
      return workItem ? workItem.mask : [];
    },
    fieldList(itemId) {
      const workItem = this.$store.state.workItems.find(
        (element) => element.id === itemId
      );
      return workItem ? workItem.fields : [];
    },
    valueType(itemId) {
      const workItem = this.$store.state.workItems.find(
        (element) => element.id === itemId
      );
      return workItem ? workItem.type : '';
    },
    getBillableInitial(itemId) {
      const farm = this.$store.state.farmList.find(
        (farm) => farm.id === parseInt(this.farmId)
      );
      if (farm && farm.priority_service) {
        return false;
      }
      const workItem = this.$store.state.workItems.find(
        (element) => element.id === itemId
      );
      return workItem ? workItem.billable : false;
    },
    showPondOption(cat_id) {
      const category = this.$store.state.workCategories.find(
        (element) => element.id === cat_id
      );
      return category && category.farm_cat === false;
    },
    showBillableOption(itemId) {
      const workItem = this.$store.state.workItems.find(
        (element) => element.id === itemId
      );
      return workItem ? workItem.billable : false;
    },
    decoder(val) {
      return decoder(val);
    },
    buildBackColor(color, opacity) {
      if (color) {
        return 'background-color: ' + colors.changeAlpha(color, opacity || 0.5);
      } else {
        return '';
      }
    },
    buildSelColor(id, opacity) {
      const category = this.$store.state.workCategories.find(
        (element) => element.id === id
      );
      return category
        ? 'background-color: ' +
            colors.changeAlpha(category.def_color, opacity || 0.5)
        : '';
    },
    navigateFarm(row) {
      this.$router.push(`/farm/${row.farm_id}/work`);
    },
    showChatClick(row) {
      this.selectedLog = row;
      this.showChat = true;
    },
    showAttachmentClick(row) {
      this.selectedLog = row;
      this.showAttachment = true;
    },
    showNoteClick(row) {
      this.selectedLog = row;
      this.showNote = true;
    },
    showOfficeNoteClick(row) {
      this.selectedLog = row;
      this.showOfficeNote = true;
    },
    serviceType(id) {
      const item = this.$store.state.workItems.find(
        (element) => element.id === id
      );
      if (!item) {
        return 'Not found';
      }
      const service = this.$store.state.workCategories.find(
        (element) => element.id === item.work_cat
      );
      return service ? service.item : 'Not found';
    },
    workItem(id) {
      const item = this.$store.state.workItems.find(
        (element) => element.id === id
      );
      return item ? item.name : 'Not found';
    },
    parseDateTime(dateTime) {
      if (!dateTime) {
        return '- - -';
      }
      return new Date(dateTime * 1000).toLocaleString('en-US');
    },
    pondName(farmId, pondId) {
      if (pondId === null) {
        return '--';
      }
      const farm = this.$store.state.farmList.find(
        (e) => e.id === parseInt(farmId)
      );
      if (farm) {
        const pond = farm.gps.ponds.find((element) => element.id === pondId);
        return pond ? pond.name : '---';
      } else {
        return '!!!';
      }
    },
    userName(id, condensed = false) {
      if (id === null) {
        return '- - -';
      }
      const user = this.$store.state.userList.find(
        (element) => element.user_id === id
      );

      if (!user) {
        return '- - - -';
      }

      // use only first letter of last name
      if (condensed) {
        return user.display_name
          .split(' ')
          .map((x, i) => (i === 0 ? x : x.substring(0, 1)))
          .join(' ');
      }

      return user.display_name;
    },
    valueDecoder(log) {
      const workItem = this.$store.state.workItems.find(
        (element) => element.id === log.work_item
      );
      if (workItem) {
        switch (workItem.type) {
          case 'fields':
            return '**';
          case 'boolean':
          case 'note':
            return '';
          case 'date':
            return parseTimeStamp(log.value, 'short');
          case 'number':
          case 'text':
            return log.value;
          case 'time':
            return parseFloat(log.time) !== 0 ? parseFloat(log.time) : '';
          default:
            return '';
        }
      }
    },
    setCategoryFilters() {
      const list = [];
      for (let i = 0; i < this.$store.state.workCategories.length; i++) {
        list.push({
          name: this.$store.state.workCategories[i].item,
          color: this.$store.state.workCategories[i].def_color,
          id: this.$store.state.workCategories[i].id,
          showItem: true
        });
      }
      this.filters.categories = list;
    },
    setUserFilters() {
      const list = [];
      list.push({
        name: 'All',
        id: -1,
        showItem: true
      });
      for (let i = 0; i < this.$store.state.userList.length; i++) {
        list.push({
          name: this.$store.state.userList[i].display_name,
          id: this.$store.state.userList[i].user_id,
          showItem: true
        });
      }
      this.filters.users = list;
    },
    farmName(farmId) {
      const farm = this.$store.state.farmList.find(
        (e) => e.id === parseInt(farmId)
      );
      return farm ? farm.farm_name : '';
    },
    userFilterInput() {
      this.userFilterDropdown = false;
    }
  },
  computed: {
    currUserId() {
      return this.$store.state.user.assigned_to;
    },
    isSuperAdmin() {
      return this.$store.state.user.aerworx_level === 'super-admin';
    },
    chatGroupAll() {
      const userList = JSON.parse(JSON.stringify(this.userList));
      const all = userList
        .filter((x) => x.chatAuth)
        .map((x) => {
          x.label = trimName(x.label);
          return x;
        });
      return all;
    },
    isAuthForChat() {
      return this.chatGroupAll.some((x) => x.value === this.currUserId);
    },
    field_values() {
      const workItem = this.$store.state.workItems.find(
        (element) => element.id === this.editLogCopy.work_item
      );
      if (!workItem) {
        return [];
      }
      const ret = [];
      for (const field_item of workItem.fields) {
        const field_value = this.editLogCopy.field_values[field_item.id];
        let value;
        switch (field_item.type) {
          case 'checkbox':
            value = field_value ? field_value.value : false;
            break;
          case 'date':
            value = field_value ? field_value.value : '';
            break;
          case 'number':
            value = field_value ? field_value.value : '';
            break;
          case 'text':
            value = field_value ? field_value.value : '';
            break;
        }
        ret.push({
          id: field_item.id,
          name: field_item.name,
          type: field_item.type,
          mask: field_item.mask,
          value
        });
      }
      return ret;
    },
    topCategory() {
      const cats = JSON.parse(
        JSON.stringify(
          this.$store.state.workCategories.filter((e) => !e.archived)
        )
      );
      cats.sort(function (a, b) {
        return a.view_order - b.view_order;
      });
      return cats.length > 0 ? cats[0] : null;
    },
    CategoryOptions() {
      const list = [];
      for (let i = 0; i < this.$store.state.workCategories.length; i++) {
        if (!this.$store.state.workCategories[i].archived) {
          list.push({
            label: this.$store.state.workCategories[i].item,
            value: this.$store.state.workCategories[i].id,
            color: this.$store.state.workCategories[i].def_color,
            view_order: this.$store.state.workCategories[i].view_order
          });
        }
      }
      list.sort(function (a, b) {
        return a.view_order - b.view_order;
      });
      return list;
    },
    ItemOptions() {
      const filtered = this.$store.state.workItems
        .filter(
          (item) =>
            item.work_cat === this.editLogCopy.work_cat && !item.archived
        )
        .sort(function (a, b) {
          return a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        });

      const list = [];
      for (let i = 0; i < filtered.length; i++) {
        list.push({
          label: filtered[i].name,
          value: filtered[i].id
        });
      }
      list.push({
        label: ' --- ',
        value: null
      });
      return list;
    },
    userList() {
      const list = [];
      for (let i = 0; i < this.$store.state.userList.length; i++) {
        list.push({
          label: this.$store.state.userList[i].display_name,
          value: this.$store.state.userList[i].assigned_to,
          chatAuth: this.$store.state.userList[i].chatAuth,
          userRole: this.$store.state.userList[i].user_role
        });
      }
      list.sort(function (a, b) {
        return a.label.localeCompare(b.label, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });
      return list;
    },
    reminderUserList() {
      const reminderRecipients = this.editLogCopy.reminders.map(
        (x) => x.reminderRecipient
      );
      return this.userList.filter((x) => !reminderRecipients.includes(x.value));
    },
    remindersFiltered() {
      return this.editLogCopy.reminders.filter(
        (x) => x.reminderRecipient === this.currUserId || this.isSuperAdmin
      );
    },
    Logs() {
      let filtered = cloneObj(this.workLogsCustom);
      // let filtered = cloneObj(this.$store.state.workLogs);

      // Filter out todos for Recent Work
      filtered = filtered.filter(
        (x) => x.todo_flag !== 10 && x.todo_flag !== 9 && x.todo_flag !== 8
      );

      for (let i = 0; i < filtered.length; i++) {
        // Deleted WorkLogs are empty str
        if (filtered[i] === '') {
          continue;
        }

        // Set last comment time for sorting
        let lastCommentTime = 0;
        if (filtered[i].comments.length) {
          lastCommentTime =
            filtered[i].comments[filtered[i].comments.length - 1].time;
        }
        filtered[i].lastCommentTime = lastCommentTime;

        const item = this.$store.getters.workItem(filtered[i].work_item);
        const cat = this.$store.getters.workCategory(filtered[i].work_cat);
        if (cat && item) {
          filtered[i].work_cat_name = cat.item;
          filtered[i].color = cat.def_color;
          filtered[i].work_item_name = item.name;
          filtered[i].master_billable = item.billable;
        }

        const chatInfoObj = chatInfo(filtered[i].chats, this.currUserId);

        filtered[i].latestMsgTs = chatInfoObj.latestMsgTs;
        filtered[i].numUnread = chatInfoObj.numUnread;
        filtered[i].numStarred = chatInfoObj.numStarred;
        filtered[i].numImportant =
          chatInfoObj.numUnread + chatInfoObj.numStarred;
        filtered[i].hasChat =
          chatInfoObj.isPartOfChat ||
          (filtered[i].chats.length > 0 && this.isSuperAdmin);
        filtered[i].isUser = filtered[i].assigned_to === this.currUserId;
        filtered[i].reminderStatus = getReminderStatus(
          filtered[i].reminders,
          this.$store.state
        );

        const officeNotesSplit = filtered[i].office_notes.split('[ Email: ');
        filtered[i].officeNotes = officeNotesSplit[0].trim();
        filtered[i].emailMsg =
          officeNotesSplit.length > 1
            ? '[ Email: ' + officeNotesSplit.pop()
            : '';
      }

      if (this.filters.billed !== -1) {
        filtered = filtered.filter((log) => log.billed === this.filters.billed);
      }

      if (this.filters.selectedUser !== -1) {
        filtered = filtered.filter(
          (log) => log.assigned_to === this.filters.selectedUser
        );
      }

      filtered = filtered.filter((log) => {
        return (
          this.filters.categories.findIndex(
            (cat) => cat.id === log.work_cat && cat.showItem
          ) !== -1
        );
      });

      filtered.sort((a, b) => b.date - a.date);

      return filtered;
    },
    ModalHeader() {
      return this.editLogCopy.id === 'new' ? 'New Log' : 'Edit Log';
    },
    valueDate: {
      get() {
        return date.formatDate(this.editLogCopy.value * 1000, 'YYYY-MM-DD');
      },
      set(value) {
        const d = new Date(value);
        this.editLogCopy.value =
          d.getTime() / 1000 + (d.getTimezoneOffset() * 60 + 3600);
      }
    },
    itemDate: {
      get() {
        return date.formatDate(this.editLogCopy.date * 1000, 'YYYY-MM-DD');
      },
      set(value) {
        const d = new Date(value);
        this.editLogCopy.date =
          d.getTime() / 1000 + (d.getTimezoneOffset() * 60 + 3600);
      }
    }
  },
  watch: {
    '$store.state.workCategories'() {
      this.setCategoryFilters();
    },
    '$store.state.userList'() {
      this.setUserFilters();
    },
    '$store.state.user'() {
      this.setBilledCol();
    },
    '$store.state.workLogsCustom'() {
      if (this.waitingForCustomWorkLogs) {
        this.workLogsCustom = this.$store.state.workLogsCustom;
        this.waitingForCustomWorkLogs = false;
        return;
      }

      if (
        this.showChat ||
        this.showAttachment ||
        this.showNote ||
        this.showOfficeNote
      ) {
        const log = this.$store.state.workLogs.find((e) => {
          return e.id === this.selectedLog.id;
        });

        const chatInfoObj = chatInfo(log.chats, this.currUserId);

        const item = this.$store.getters.workItem(log.work_item);
        const cat = this.$store.getters.workCategory(log.work_cat);
        if (cat && item) {
          log.work_cat_name = cat.item;
          log.color = cat.def_color;
          log.work_item_name = item.name;
          log.master_billable = item.billable;
          log.numUnread = chatInfoObj.numUnread;
          log.numStarred = chatInfoObj.numStarred;
          log.numImportant = chatInfoObj.numUnread + chatInfoObj.numStarred;
          log.latestMsgTs = chatInfoObj.latestMsgTs;
          log.hasChat =
            chatInfoObj.hasChat || (log.chats.length && this.isSuperAdmin);
          log.isUser = log.assigned_to === this.currUserId;
        }

        const officeNotesSplit = log.office_notes.split('[ Email: ');
        log.officeNotes = officeNotesSplit[0].trim();
        log.emailMsg =
          officeNotesSplit.length > 1
            ? '[ Email: ' + officeNotesSplit.pop()
            : '';

        this.selectedLog = JSON.parse(JSON.stringify(log));
      }
    },
    '$store.state.workLogs'(logs) {
      // We need to wait until worklogs are in - otherwise
      // we get a network error on reload
      if (logs.length && !this.workLogsLoaded) {
        this.getInitialLoad();
      }
    }
  }
};
</script>

<style scoped></style>
