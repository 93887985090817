<template>
  <q-card style="width: 510px" :class="$q.screen.xs ? 'q-pa-md' : 'q-pa-lg'">
    <q-form @submit="onSubmit">
      <div
        class="row justify-center full-width text-h6 text-white bg-primary q-py-sm q-mb-md"
      >
        <span v-if="editLogCopy.id === 'new'">New Log</span>
        <span v-if="editLogCopy.id !== 'new'">Edit Log</span>
      </div>

      <div class="q-mb-sm">
        <q-select
          v-model="editLogCopy.work_cat"
          square
          filled
          :options="categoryOptions"
          :style="buildSelColor(editLogCopy.work_cat, 0.2)"
          emit-value
          map-options
          @input="editCategoryChanged"
          label="Category"
        >
          <template v-slot:option="scope">
            <q-item
              v-bind="scope.itemProps"
              v-on="scope.itemEvents"
              :style="buildBackColor(scope.opt.color, 0.2)"
            >
              <q-item-section>
                <q-item-label v-html="scope.opt.label" />
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>

      <div class="q-mb-sm">
        <q-select
          v-model="editLogCopy.work_item"
          square
          dense
          filled
          :options="itemOptions"
          emit-value
          map-options
          label="Item"
          @input="workItemChanged"
        />
      </div>

      <div class="q-mb-sm">
        <q-select
          v-if="showPondOption(editLogCopy.work_cat)"
          v-model="pondOrPcModel"
          square
          dense
          filled
          :options="pondOrPcOptions"
          emit-value
          map-options
          label="Pond / PC"
        />
      </div>

      <div v-if="locationOptions.length" class="q-mb-sm">
        <q-select
          v-model="locationModel"
          square
          dense
          filled
          :options="locationOptions"
          emit-value
          map-options
          label="Location"
        />
      </div>

      <div class="q-mb-sm">
        <q-input
          v-if="valueType(editLogCopy.work_item) === 'time'"
          filled
          dense
          v-model="editLogCopy.time"
          label="Time"
          step="any"
          min="0"
          mask="#####"
          type="number"
          outlined
          square
          hide-bottom-space
          :rules="[
            (val) =>
              checkForExtraDecimals(val) || 'Only 1 decimal place allowed!'
          ]"
        />
      </div>

      <div v-if="isSuperAdmin" class="q-mb-sm">
        <q-input
          filled
          dense
          v-model="keyword"
          type="textarea"
          label="Keyword"
          maxlength="1024"
          outlined
          square
          autogrow
        />
      </div>

      <div v-if="isSuperAdmin" class="q-mb-sm">
        <q-input
          filled
          dense
          v-model="officeNoteShort"
          type="textarea"
          label="Office Notes"
          maxlength="1024"
          outlined
          square
          autogrow
        />
      </div>

      <div class="q-mb-sm">
        <q-input
          filled
          dense
          v-model="editLogCopy.notes"
          type="textarea"
          label="Field Notes"
          maxlength="1024"
          outlined
          square
          autogrow
        />
      </div>

      <div v-if="isSuperAdmin" class="q-mb-sm">
        <q-select
          v-model="editLogCopy.assigned_to"
          square
          dense
          filled
          :options="userList"
          emit-value
          map-options
          label="Assigned To"
        />
      </div>

      <template v-if="valueType(editLogCopy.work_item) === 'fields'">
        <div v-for="(field, index) in fieldValues" :key="index">
          <div class="q-mb-sm">
            <q-toggle
              v-if="field.type === 'checkbox'"
              :label="field.name"
              v-model="editLogCopy.field_values[field.id]"
            />
          </div>

          <div class="q-mb-sm">
            <q-input
              v-if="field.type === 'date'"
              filled
              dense
              v-model="editLogCopy.field_values[field.id]"
              :label="field.name"
              type="date"
              outlined
              square
            />
          </div>

          <div class="q-mb-sm">
            <q-input
              v-if="field.type === 'number'"
              step="any"
              filled
              dense
              v-model.number="editLogCopy.field_values[field.id]"
              :label="field.name"
              type="number"
              outlined
              square
            />
          </div>

          <div class="q-mb-sm">
            <q-input
              v-if="field.type === 'text'"
              filled
              dense
              v-model="editLogCopy.field_values[field.id]"
              :label="field.name"
              :mask="field.mask"
              outlined
              square
            />
          </div>

          <div class="q-mb-sm">
            <q-select
              v-if="field.type === 'fields'"
              dense
              v-model="editLogCopy.field_values[field.id]"
              outlined
              :options="field.mask.split(',')"
              emit-value
              map-options
              :label="field.name"
            />
          </div>
        </div>
      </template>

      <div class="q-mb-sm">
        <q-input
          filled
          square
          dense
          label="Log Date"
          v-model="workLogDate"
          lazy-rules
          :rules="[(val) => isLogDateValid(val) || 'Invalid date']"
          hide-bottom-space
        >
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer" color="primary">
              <q-popup-proxy
                ref="qDateProxy"
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date v-model="workLogDate" mask="MM/DD/YYYY">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>

      <div v-if="editLogCopy.todo_flag === 2" class="q-mb-sm">
        <q-input
          filled
          square
          dense
          label="Complete Date"
          v-model="completeDate"
          :rules="[
            (val) =>
              isCompleteDateValid(val) ||
              'Completed date must be valid and must be equal or greater than log date!'
          ]"
          hide-bottom-space
        >
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer" color="primary">
              <q-popup-proxy
                ref="qDateProxy"
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date v-model="completeDate" mask="MM/DD/YYYY">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>

      <div class="row items-center q-mb-sm">
        <q-space />
        <div>
          <q-toggle
            dense
            keep-color
            :color="editLogCopy.billable ? 'green-14' : 'red-14'"
            v-model="editLogCopy.billable"
          >
            <div
              class="text-subtitle1"
              :class="editLogCopy.billable ? 'text-green-14' : 'text-red-14'"
            >
              Billable
            </div>
          </q-toggle>
        </div>
      </div>

      <div class="q-mb-md" style="border: 1px solid #2962ff">
        <div class="row flex-center text-subtitle1 text-primary">
          <span class="q-pr-sm"> ToDo Flag </span>
          <q-btn
            unelevated
            :color="buildToDoColor(editLogCopy.todo_flag)"
            style="width: 24px; height: 14px"
          />
        </div>
        <div class="text-center q-pb-sm">
          <q-btn
            :outline="editLogCopy.todo_flag !== 10"
            color="primary"
            label="Critical"
            size="sm"
            class="q-mx-sm q-mt-sm"
            style="width: 65px"
            @click="editLogCopy.todo_flag = 10"
          />
          <q-btn
            :outline="editLogCopy.todo_flag !== 9"
            color="primary"
            label="High"
            size="sm"
            class="q-mx-sm q-mt-sm"
            style="width: 65px"
            @click="editLogCopy.todo_flag = 9"
          />
          <q-btn
            :outline="editLogCopy.todo_flag !== 8"
            color="primary"
            label="Medium"
            size="sm"
            class="q-mx-sm q-mt-sm"
            style="width: 65px"
            @click="editLogCopy.todo_flag = 8"
          />
          <q-btn
            :outline="editLogCopy.todo_flag !== 7"
            color="primary"
            label="Low"
            size="sm"
            class="q-mx-sm q-mt-sm"
            style="width: 65px"
            @click="editLogCopy.todo_flag = 7"
          />
          <q-btn
            :outline="editLogCopy.todo_flag !== 2"
            color="primary"
            label="Complete"
            size="sm"
            class="q-mx-sm q-mt-sm"
            style="width: 65px"
            @click="editLogCopy.todo_flag = 2"
          />
          <q-btn
            v-if="isSuperAdmin"
            :outline="editLogCopy.todo_flag !== 1"
            color="primary"
            label="Reviewed"
            size="sm"
            class="q-mx-sm q-mt-sm"
            style="width: 65px"
            @click="editLogCopy.todo_flag = 1"
          />
          <q-btn
            v-if="isSuperAdmin"
            :outline="editLogCopy.todo_flag !== 0"
            color="primary"
            label="None"
            size="sm"
            class="q-mx-sm q-mt-sm"
            style="width: 65px"
            @click="editLogCopy.todo_flag = 0"
          />
        </div>
      </div>

      <div class="row full-width">
        <q-btn
          unelevated
          v-if="editLogCopy.id !== 'new'"
          icon="delete"
          label="Delete"
          color="grey-3"
          text-color="red-14"
          size="md"
          padding="xs sm"
          @click="deleteLog(editLogCopy)"
        />
        <q-space />
        <q-btn
          unelevated
          label="Cancel"
          color="grey-2"
          text-color="black"
          class="q-mr-sm"
          @click="$emit('cancel')"
        />
        <q-btn
          label="Submit"
          type="submit"
          :loading="(loading && isFarmWork) || submitted"
          color="primary"
        />
      </div>
    </q-form>
  </q-card>
</template>

<script>
import { decoder, cloneObj } from '@/lib/helpers';
import { parseTimeStamp } from '@/lib/date-utils';
import { todoOptions } from '@/lib/static-data';
import { colors, date } from 'quasar';
import store from '@/store';
import image from '@/assets/pdf/AerWorx_logo_pdf.png';

export default {
  name: 'WorkOrderRow',
  data() {
    return {
      completeDate: null,
      completeDateTS: null,
      completeOnEnter: false,
      editLogCopy: {},
      keyword: '',
      officeNoteShort: '',
      pondOrPcModel: null,
      locationModel: null,
      submitted: false,
      workLogDate: null
    };
  },
  mounted() {
    this.formatLog(this.workLog);
  },
  props: {
    farmId: {
      type: Number,
      required: true
    },
    workLog: {
      type: Object,
      required: true
    }
  },
  methods: {
    buildBackColor(color, opacity) {
      if (color) {
        return 'background-color: ' + colors.changeAlpha(color, opacity || 0.5);
      } else {
        return '';
      }
    },
    buildOfficeNote() {
      const keyword = this.keyword.toUpperCase().trim();
      const officeNote = this.officeNoteShort.trim();
      const dividerStr =
        keyword.length > 1 && officeNote.length > 1 ? ' - ' : '';
      const keywordStr = keyword.length > 1 ? keyword + dividerStr : '';
      const emailStrArr = this.editLogCopy.office_notes.split('[ Email:');
      const email =
        emailStrArr.length > 1 ? ' [ Email:' + emailStrArr.pop() : '';

      return keywordStr + officeNote + email;
    },
    buildSelColor(id, opacity) {
      const category = this.$store.state.workCategories.find(
        (element) => element.id === id
      );
      return category
        ? 'background-color: ' +
            colors.changeAlpha(category.def_color, opacity || 0.5)
        : '';
    },
    buildToDoColor(todoFlag) {
      return todoOptions.find((x) => x.value === todoFlag)?.color ?? '';
    },
    checkForExtraDecimals(val) {
      const split = val.toString().split('.');
      return !split[1] ? true : split[1].length <= 1;
    },
    deleteLog(log) {
      this.$q
        .dialog({
          title: 'Confirm',
          message: `Are you sure you want to delete log @ ${parseTimeStamp(
            log.date,
            'short'
          )}?`,
          ok: {
            color: 'red-14',
            icon: 'delete',
            label: 'Delete'
          },
          cancel: {
            flat: true,
            color: 'primary',
            label: 'cancel'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.$emit('cancel');
          this.dispatchDelete(log);
        });
    },
    dispatchDelete(log) {
      store
        .dispatch('deleteWorkLog', {
          farmId: this.farmId,
          log
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.dispatchDelete(log);
            }
          });
        });
    },
    editCategoryChanged() {
      if (this.itemOptions.length === 1) {
        this.editLogCopy.work_item = this.itemOptions[0].value;
      } else {
        this.editLogCopy.work_item = null;
      }
      if (!this.showPondOption(this.editLogCopy.work_cat)) {
        this.editLogCopy.pond_id = null;
      }
    },
    fieldCopyHandler() {
      const workItem = this.$store.state.workItems.find(
        (element) => element.id === this.editLogCopy.work_item
      );
      if (!workItem || workItem.type !== 'fields') {
        return {};
      }
      const field_copy = {};
      for (const field_item of workItem.fields) {
        if (
          typeof this.editLogCopy.field_values[field_item.id] === 'undefined'
        ) {
          switch (field_item.type) {
            case 'checkbox':
              field_copy[field_item.id] = false;
              break;
            case 'date':
              field_copy[field_item.id] = '---';
              break;
            case 'number':
              field_copy[field_item.id] = '';
              break;
            case 'text':
              field_copy[field_item.id] = '';
              break;
          }
        } else {
          field_copy[field_item.id] =
            this.editLogCopy.field_values[field_item.id];
        }
      }
      this.editLogCopy.field_values = field_copy;
    },
    formatLog(log) {
      this.editLogCopy = cloneObj(log);
      this.todoFlag = this.editLogCopy.todo_flag;
      this.editLogCopy.time = parseFloat(this.editLogCopy.time);

      const keywordStr = this.editLogCopy.office_notes.split(' - ')[0];
      this.keyword = keywordStr === keywordStr.toUpperCase() ? keywordStr : '';
      const withoutKeywordStr =
        this.keyword === ''
          ? this.editLogCopy.office_notes.trim()
          : this.editLogCopy.office_notes
              .split(' - ')
              .slice(1)
              .join(' - ')
              .trim();
      this.officeNoteShort = withoutKeywordStr.split('[ Email:')[0].trim();

      this.pondOrPcModel = this.editLogCopy.pond_id
        ? this.editLogCopy.pond_id
        : this.editLogCopy.computer_id
        ? this.editLogCopy.computer_id
        : null;

      this.locationModel = this.editLogCopy.location_id;

      this.workLogDate = date.formatDate(
        this.editLogCopy.date * 1000,
        'MM/DD/YYYY'
      );
      this.completeDate =
        this.editLogCopy.todo_flag === 2
          ? date.formatDate(this.editLogCopy.todo_complete * 1000, 'MM/DD/YYYY')
          : date.formatDate(new Date(), 'MM/DD/YYYY');
      this.completeDateTS =
        this.editLogCopy.todo_flag === 2
          ? this.editLogCopy.todo_complete * 1000
          : +new Date();
      this.completeOnEnter = this.editLogCopy.todo_flag === 2;

      this.fieldCopyHandler();
      if (this.workLog.id === 'new') {
        this.editCategoryChanged();

        // if cat === services set item to Service/Repair General
        if (this.editLogCopy.work_cat === 3) {
          this.editLogCopy.work_item = 113;
        }

        // if cat === Daily Log set item to Notes
        if (this.editLogCopy.work_cat === 12) {
          this.editLogCopy.work_item = 105;
        }

        // if cat === WebEval or PC check or Win Updates
        if (this.editLogCopy.work_cat === 13) {
          this.editLogCopy.work_item = log.work_item;
        }
      }

      this.showEditModal = true;
    },
    getBackColor(color, opacity) {
      if (color) {
        return 'background-color: ' + colors.changeAlpha(color, opacity || 0.5);
      } else {
        return '';
      }
    },
    getBillableInitial(itemId) {
      const farm = this.$store.state.farmList.find(
        (farm) => farm.id === parseInt(this.farmId)
      );
      if (farm && farm.priority_service) {
        return false;
      }
      const workItem = this.$store.state.workItems.find(
        (element) => element.id === itemId
      );
      return workItem ? workItem.billable : false;
    },
    getToDoColor(todoFlag) {
      return todoOptions.find((x) => x.value === todoFlag)?.color ?? '';
    },
    getTodoType(todoFlag) {
      return todoOptions.find((x) => x.value === todoFlag)?.label ?? '';
    },
    isAuthForChat(chat) {
      if (this.isSuperAdmin) {
        return true;
      }
      return chat.chatGroup.some((x) => x.value === this.currUserId);
    },
    isLogDateValid(val) {
      const dateSplit = val.split('/');
      const validMo = dateSplit[0] && dateSplit[0].length === 2;
      const validDay = dateSplit[1] && dateSplit[1].length === 2;
      const validYr = dateSplit[2] && dateSplit[2].length === 4;

      if (date.isValid(val) && validMo && validDay && validYr) {
        return true;
      }

      return false;
    },
    isCompleteDateValid(val) {
      const logDate = +new Date(this.workLogDate);
      const startOfLog = +date.startOfDate(logDate, 'day');
      return +new Date(val) >= startOfLog;
    },
    keywordToUpper() {
      this.keyword = this.keyword.toUpperCase();

      const notesSplitArr = this.officeNoteShort.split(' - ');
      const hasKeyword = notesSplitArr[0].toUpperCase() === notesSplitArr[0];
      if (hasKeyword) {
        notesSplitArr.shift();
      }

      const keywordStr = this.keyword.length ? this.keyword + ' - ' : '';
      this.officeNoteShort = notesSplitArr.join(' - ');
    },
    onSubmit() {
      if (this.editLogCopy.work_item === null) {
        this.$q.notify({
          message: 'Please select Work item',
          color: 'accent'
        });
        return;
      }

      const yearAgo = +date.subtractFromDate(new Date(), { year: 1 });

      if (+new Date(this.workLogDate) < yearAgo) {
        this.$q
          .dialog({
            title: 'Log Date Warning',
            message: 'Log date is greater than 1 year ago. Is this correct?',
            ok: {
              label: 'Yes',
              color: 'primary'
            },
            cancel: {
              flat: true,
              label: 'Cancel',
              color: 'primary'
            },
            focus: 'none',
            persistent: true
          })
          .onOk(() => {
            this.onSubmitConfirmed();
          });

        return;
      }

      this.onSubmitConfirmed();
    },
    async onSubmitConfirmed() {
      this.submitted = true;

      let dispatch;
      if (this.editLogCopy.id === 'new') {
        dispatch = 'createWorkLog';
        sessionStorage.setItem('lastCategory', this.editLogCopy.work_cat);
        this.editLogCopy.billed_changed_by = null;
        this.editLogCopy.billed_changed_on = null;
        // Set created time
        this.editLogCopy.created_date = date.formatDate(new Date(), 'X');
        this.editLogCopy.reminders = [];
      } else {
        dispatch = 'updateWorkLog';
      }

      const pondOrPc = this.pondOrPcOptions.find(
        (x) => x.value === this.pondOrPcModel
      );

      if (pondOrPc.type === 'pond') {
        this.editLogCopy.pond_id = this.pondOrPcModel;
        this.editLogCopy.computer_id = null;
      } else if (pondOrPc.type === 'computer') {
        this.editLogCopy.pond_id = null;
        this.editLogCopy.computer_id = this.pondOrPcModel;
      }

      this.editLogCopy.location_id = this.locationModel;
      this.editLogCopy.location_name =
        this.locationOptions.find((x) => x.value === this.locationModel)
          ?.label ?? null;

      const completeTS = this.completeOnEnter
        ? this.completeDateTS
        : +new Date();

      this.editLogCopy.todo_complete =
        this.editLogCopy.todo_flag === 2
          ? date.formatDate(completeTS, 'X')
          : null;

      this.editLogCopy.date = date.formatDate(new Date(this.workLogDate), 'X');

      if (
        this.todoFlag !== this.editLogCopy.todo_flag &&
        this.editLogCopy.todo_flag !== 1
      ) {
        this.editLogCopy.todo_complete_by =
          this.editLogCopy.todo_flag === 2 ? this.currUserId : null;
      }

      this.editLogCopy.time =
        this.editLogCopy.time === '' || !this.editLogCopy.time
          ? 0
          : this.editLogCopy.time;
      this.editLogCopy.time = Math.round(this.editLogCopy.time * 10) / 10;

      this.editLogCopy.office_notes = this.buildOfficeNote();

      store.dispatch('setQuickAddComplete', true);

      if (store.state.screenshot) {
        this.$emit('addScreenshot', this.editLogCopy);
      }

      store
        .dispatch(dispatch, {
          farmId: this.farmId,
          log: this.editLogCopy
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.onSubmit();
            } else {
              this.$emit('submitted');
              this.$emit('cancel');
            }
          });
        });
    },
    showPondOption(catId) {
      const category = this.$store.state.workCategories.find(
        (element) => element.id === catId
      );
      return category && category.farm_cat === false;
    },
    valueMask(itemId) {
      const workItem = this.$store.state.workItems.find(
        (element) => element.id === itemId
      );
      return workItem ? workItem.mask : [];
    },
    valueType(itemId) {
      const workItem = this.$store.state.workItems.find(
        (element) => element.id === itemId
      );
      return workItem ? workItem.type : '';
    },
    workItemChanged() {
      const type = this.valueType(this.editLogCopy.work_item);
      this.editLogCopy.time = '';
      this.editLogCopy.billable = this.getBillableInitial(
        this.editLogCopy.work_item
      );
      this.fieldCopyHandler();
    }
  },
  computed: {
    categoryOptions() {
      const list = [];
      for (let i = 0; i < this.$store.state.workCategories.length; i++) {
        if (!this.$store.state.workCategories[i].archived) {
          list.push({
            label: this.$store.state.workCategories[i].item,
            value: this.$store.state.workCategories[i].id,
            color: this.$store.state.workCategories[i].def_color,
            view_order: this.$store.state.workCategories[i].view_order
          });
        }
      }
      list.sort((a, b) => a.view_order - b.view_order);
      return list;
    },
    currUserId() {
      return this.$store.state.user.user_id;
    },
    fieldValues() {
      const workItem = this.$store.state.workItems.find(
        (element) => element.id === this.editLogCopy.work_item
      );
      if (!workItem) {
        return [];
      }
      const ret = [];
      for (const field_item of workItem.fields) {
        const field_value = this.editLogCopy.field_values[field_item.id];
        let value;
        switch (field_item.type) {
          case 'checkbox':
            value = field_value ? field_value.value : false;
            break;
          case 'date':
            value = field_value ? field_value.value : '';
            break;
          case 'number':
            value = field_value ? field_value.value : '';
            break;
          case 'text':
            value = field_value ? field_value.value : '';
            break;
        }
        ret.push({
          id: field_item.id,
          name: field_item.name,
          type: field_item.type,
          mask: field_item.mask,
          value
        });
      }
      return ret;
    },
    isFarmWork() {
      return this.$route.name === 'Farm-Work';
    },
    isSuperAdmin() {
      return this.$store.state.user.aerworx_level === 'super-admin';
    },
    itemOptions() {
      const list = this.$store.state.workItems
        .filter(
          (item) =>
            item.work_cat === this.editLogCopy.work_cat && !item.archived
        )
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((item) => ({ label: item.name, value: item.id }));

      if (list.length > 1) {
        list.push({
          label: ' --- ',
          value: null
        });
      }
      return list;
    },
    loading() {
      return this.$store.state.farm.loading;
    },
    locationOptions() {
      const locations =
        this.$store.state.farmList.find((x) => x.id === this.farmId)
          ?.locations ?? [];

      const options = [];
      for (const location of locations) {
        options.push({
          label: location.name,
          value: location.id
        });
      }

      return options;
    },
    pondOrPcOptions() {
      const farm = this.$store.state.farmList.find(
        (x) => x.id === this.editLogCopy.farm_id
      );

      const harvestBouys = [];
      const pondOrPcList = [];
      if (farm) {
        for (const pond of farm.gps.ponds) {
          const pondName = pond.name.toLowerCase();

          if (pondName.includes('harvest') || pondName.includes('hb')) {
            harvestBouys.push({
              label: pond.name,
              value: pond.id,
              type: 'pond'
            });
          } else {
            pondOrPcList.push({
              label: pond.name,
              value: pond.id,
              type: 'pond'
            });
          }
        }
      }

      // properly sort pond numbers
      pondOrPcList.sort((a, b) =>
        a.label.localeCompare(b.label, undefined, {
          numeric: true,
          sensitivity: 'base'
        })
      );

      pondOrPcList.push({
        label: ' --- ',
        value: null,
        type: 'pond'
      });

      for (const bouy of harvestBouys) {
        pondOrPcList.push(bouy);
      }

      if (farm) {
        for (const computer of farm.gps.computers) {
          pondOrPcList.push({
            label: computer.name,
            value: computer.id,
            type: 'computer'
          });
        }
      }

      return pondOrPcList;
    },
    userNames(id, condensed = false) {
      const userNameMap = new Map();

      for (const user of this.$store.state.userList) {
        const shortName = user.display_name
          .split(' ')
          .map((x, i) => (i === 0 ? x : x.substring(0, 1)))
          .join(' ');

        userNameMap.set(user.user_id, shortName);
      }

      return userNameMap;
    },
    userList() {
      const list = [];
      for (let i = 0; i < this.$store.state.userList.length; i++) {
        list.push({
          label: this.$store.state.userList[i].display_name,
          value: this.$store.state.userList[i].user_id,
          chatAuth: this.$store.state.userList[i].chatAuth,
          userRole: this.$store.state.userList[i].user_role
        });
      }

      list.sort((a, b) => a.label.localeCompare(b.label));

      list.unshift({
        label: '---',
        value: null,
        chatAuth: false,
        userRole: null
      });

      return list;
    }
  }
};
</script>

<style scoped></style>
